<template>
  <el-dialog title="用户详情" :visible.sync="userInfoDetailVisible" :close-on-click-modal="false" width="900px" append-to-body :before-close="handleClose">
    <el-form :model="ruleForm"  ref="ruleForm" label-width="130px" class="demo-ruleForm">
      <div class="title">个人信息</div>
      <el-row>
        <el-col :span="12">
          <el-form-item label="真实姓名：" prop="name">
            <span>{{ruleForm.name || '--'}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号：" prop="name">
            <span>{{ruleForm.idNumber || '--'}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="手机号：" prop="name">
            <span>{{ruleForm.contactPhone || '--'}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属单位：" prop="name">
            <span>{{unitName || '--'}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="所属部门：" prop="name">
            <span>{{ruleForm.departmentName || '--'}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属角色：" prop="name">
            <span>{{ruleForm.roleName || '--'}}</span>
          </el-form-item>
        </el-col>
      </el-row>
<!--      <div class="title">账号信息</div>-->
<!--      <el-row>-->
<!--        <el-col :span="12">-->
<!--          <el-form-item label="用户名：" prop="name">-->
<!--            <span>真实姓名</span>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--      <el-row>-->
<!--        <el-col :span="12">-->
<!--          <el-form-item label="密码：" prop="name">-->
<!--            <span>真实姓名</span>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :span="12">-->
<!--          <el-form-item label="确认密码：" prop="name">-->
<!--            <span>真实姓名</span>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--&lt;!&ndash;        <div class="password">密码可输入6-16位之间的数字或字母（字母区分大小写）,在设置时尽量不要出现连续数字的情况</div>&ndash;&gt;-->
<!--      </el-row>-->
    </el-form>
    <div slot="footer">
      <el-button @click="handleClose">取 消</el-button>
<!--      <el-button type="primary" @click="handleClose">确 定</el-button>-->
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: "userInfoDetailDilog",
    props: {
      userInfoDetailVisible: {
        type: Boolean,
        default: false
      },
      detailData: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
    watch: {
      detailData: {
        deep: true,
        handler(val) {
          this.ruleForm = val
        }
      }
    },
    data() {
      return {
        ruleForm: {},
        unitName: ''
      }
    },
    mounted() {
      this.unitName = JSON.parse(localStorage.getItem('cad_userInfo')).unitName
    },
    methods: {
      handleClose() {
        this.$emit('update:userInfoDetailVisible', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .w230 {
    width: 230px;
  }
  .info {
    margin-left: 130px;
    width: 300px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
  }
  .title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  .password {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
    margin-left: 130px;
  }
</style>
